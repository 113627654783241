"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
function BackupNasaPhoto() {
    const [nasaArchivePhoto, setNasaArchivePhoto] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        const fetchData = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield fetch('https://images-api.nasa.gov/search?description=%22earth%20images%20from%20the%20moon%22');
                const data = yield res.json();
                setNasaArchivePhoto(data.collection.items[1].data[0]);
            }
            catch (error) {
                console.log(error);
            }
        });
        fetchData();
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null, nasaArchivePhoto ? (react_1.default.createElement("section", { className: "picture-of-the-day-section" },
        react_1.default.createElement("p", null,
            react_1.default.createElement("strong", null, "Attention: The Astronomy picture of the day is not available. This image is from the archives of Nasa.")),
        react_1.default.createElement("h2", { className: "picture-title" }, nasaArchivePhoto.title),
        react_1.default.createElement("img", { className: "picture-image", src: "https://images-assets.nasa.gov/image/PIA21260/PIA21260~orig.jpg", alt: "see explanation" }),
        react_1.default.createElement("h2", { className: "picture-explanation-title" }, "Explanation"),
        react_1.default.createElement("p", { className: "picture-explanation-text" }, nasaArchivePhoto.description),
        react_1.default.createElement("p", { className: "picture-source" },
            react_1.default.createElement("strong", null, "Picture source: NASA")))) : null));
}
exports.default = BackupNasaPhoto;
