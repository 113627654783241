"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function parseDate(date) {
    const year = date.slice(0, 4);
    let month = date.slice(5, 7);
    const day = date.slice(8, 10);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    if (month[0] === '0') {
        month = month[1];
    }
    month = months[parseInt(month) - 1];
    return `${month} ${day}, ${year}`;
}
exports.default = parseDate;
