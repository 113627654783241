"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
function ClimateData({ dataset, setDataset, handleSelectChange }) {
    //Fetching datasets
    (0, react_1.useEffect)(() => {
        const url = 'https://real-time-climate-index.p.rapidapi.com/api/climate-data';
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': process.env.RAPID_API_KEY || ''
            },
        };
        function fetchDatasets() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const response = yield fetch(url, options);
                    const result = yield response.json();
                    const data = result.slice(1);
                    setDataset(data);
                }
                catch (error) {
                    console.error(error);
                }
            });
        }
        fetchDatasets();
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null, dataset.length ? (react_1.default.createElement("section", { className: "climate-select" },
        react_1.default.createElement("h2", null, "Climate Data"),
        react_1.default.createElement("label", { htmlFor: "dataset-select", className: "climate-select-label" }, "Select a climate concern to learn more"),
        react_1.default.createElement("select", { id: "dataset-select", onChange: handleSelectChange },
            react_1.default.createElement("option", null, "Select"),
            dataset.map((data, i) => {
                return (react_1.default.createElement("option", { key: data[0]['metadata']['name'], value: i }, data[0]['metadata']['name']));
            })))) : null));
}
exports.default = ClimateData;
